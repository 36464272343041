/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
.ctabutton {
  display: inline-block;
  /* reset for <button> */
  border: 0;
  padding: 0;
  -webkit-appearance: none;
  /* NEVER overflow */
  max-width: 100%;
  /* NEVER let content within overflow */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* reset for <a> */
  text-decoration: none;
  color: #FFFFFF;
  background-color: #BE311A;
  border-radius: 3px;
  font: bold 16px "TypoPRO Open Sans";
  text-align: left;
  cursor: pointer;
}

.ctabutton:active,
.ctabutton:hover,
.wh-form__button:active,
.wh-form__button:hover {
  background-color: #BE311A;
}

.ctabutton--small {
  height: 35px;
  line-height: 35px;
  padding: 0 15px;
  text-transform: uppercase;
}

.ctabutton--big {
  height: 59px;
  line-height: 59px;
  padding: 0 25px;
  text-transform: uppercase;
}

.ctabutton--gobackward,
.ctabutton--goforward {
  display: flex;
  align-items: center;
}

.ctabutton--goforward .ctabutton__text {
  /* eat excess space */
  flex: 1;
}

.ctabutton--goforward::after {
  margin-left: 15px;
  font: normal normal normal 14px FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  font-size: 75%;
  content: "\f054";
  flex-shrink: 0;
  align-self: center;
}

.ctabutton--goforward.ctabutton--big::after {
  font-size: 18px;
}