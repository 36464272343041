/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
.page--admin {
  display: flex;
}

/* settings column */
.admin__settings {
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  width: 345px;
  padding: 0 20px 40px 20px;
}

.admin__status {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 0 20px 20px 20px;
}

.admin__status__closebutton {
  font-weight: normal;
  margin-left: auto;
  cursor: pointer;
}

.admin__status__closebutton::after {
  margin-left: 10px;
  content: "";
  background: transparent url("../web/img/close-white.svg") no-repeat scroll center center;
  background-size: 15px 15px;
  width: 15px;
  height: 15px;
  padding: 10px;
  box-sizing: content-box;
}

#admin__event,
#admin__savebtn {
  width: 100%;
}

#updatebutton {
  margin-top: 20px;
}

.panel + .panel {
  margin-top: 45px;
}

.admin__status__eventname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin__status__header {
  color: #BE311A;
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 4px;
  border-bottom: 1px solid #D4D4D4;
  margin-bottom: 15px;
}

.panel--log {
  display: flex;
  flex-direction: column;
}

.panel--log,
#admin__status__log {
  flex: 1;
}

#admin__status__log {
  overflow: auto;
}

.logbutton {
  display: inline-block;
  background-color: #e5e5e5;
  padding: 2px 6px;
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
}

.logbutton:hover {
  background-color: #a0a0a0;
  color: #FFFFFF;
}