/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
input[type=text],
input[type=tel],
input[type=url],
input[type=email],
input[type=password],
input[type=number],
input[type=date],
input[type=search],
select,
.custom-pulldown {
  height: 35px;
  height: var(--formcontrols-height);
  border: 1px solid #D4D4D4;
  border: 1px solid var(--formcontrols-border-color);
  border-radius: 3px;
  border-radius: var(--formcontrols-radius);
}

select,
.custom-pulldown {
  font: inherit;
  line-height: 33px;
  padding: 0 15px;
  width: 100%;
  background: #ffffff;
}

input[type=text],
input[type=tel],
input[type=url],
input[type=email],
input[type=password],
input[type=number],
input[type=date],
input[type=search] {
  font: inherit;
  padding: 0 10px;
  flex-grow: 1;
  box-shadow: none;
}

#form-phone-countrycode,
#form-phone-countrycode.custom-pulldown--replaced + .custom-pulldown {
  flex: 1 0 140px;
  max-width: max-content; /* don't grow beyond the width needed for the largest option title */
}

#form-phone {
  flex: 1 1 80px;
  min-width: 1px; /* default is auto which lets the browser decide what it thinks is a good value */
  margin-left: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

form.attemptedsubmit input[type=text]:invalid,
form.attemptedsubmit input[type=tel]:invalid,
form.attemptedsubmit input[type=url]:invalid,
form.attemptedsubmit input[type=email]:invalid,
form.attemptedsubmit input[type=password]:invalid,
form.attemptedsubmit input[type=number]:invalid,
form.attemptedsubmit input[type=date]:invalid,
form.attemptedsubmit input[type=search]:invalid,
form.attemptedsubmit textarea:invalid,
form.attemptedsubmit select:invalid,
form.attemptedsubmit select:invalid + .custom-pulldown,
form.attemptedsubmit input[type=checkbox]:invalid,
form.attemptedsubmit input[type=radio]:invalid,
form.attemptedsubmit input[type=checkbox]:invalid + label::before,
form.attemptedsubmit input[type=radio]:invalid + label::before,
form.attemptedsubmit input[type=text].invalid,
form.attemptedsubmit input[type=tel].invalid,
form.attemptedsubmit input[type=url].invalid,
form.attemptedsubmit input[type=email].invalid,
form.attemptedsubmit input[type=password].invalid,
form.attemptedsubmit input[type=number].invalid,
form.attemptedsubmit input[type=date].invalid,
form.attemptedsubmit input[type=search].invalid,
form.attemptedsubmit textarea.invalid,
form.attemptedsubmit select.invalid,
form.attemptedsubmit select.invalid + .custom-pulldown {
  border-color: #F00;
}

#form-email-entity,
#form-email-host {
  width: 0; /* allow to shrink so all fields can fit */
}

.wh-styledinput input[type=text]:hover,
.wh-styledinput input[type=text]:focus,
.wh-styledinput input[type=tel]:hover,
.wh-styledinput input[type=tel]:focus,
.wh-styledinput input[type=url]:hover,
.wh-styledinput input[type=url]:focus,
.wh-styledinput input[type=email]:hover,
.wh-styledinput input[type=email]:focus,
.wh-styledinput input[type=password]:hover,
.wh-styledinput input[type=password]:focus,
.wh-styledinput input[type=number]:hover,
.wh-styledinput input[type=number]:focus,
.wh-styledinput input[type=date]:hover,
.wh-styledinput input[type=date]:focus,
.wh-styledinput input[type=search]:hover,
.wh-styledinput input[type=search]:focus,
.wh-styledinput textarea:hover,
.wh-styledinput textarea:focus,
.wh-styledinput select:hover,
.wh-styledinput select:focus,
.wh-styledinput .custom-pulldown:hover,
.wh-styledinput .custom-pulldown:focus {
  border-color: #5A5A5A;
  outline: 0;
}

.wh-styledinput input[type=text][disabled], .wh-styledinput input[type=text][readonly],
.wh-styledinput input[type=url][disabled], .wh-styledinput input[type=url][readonly],
.wh-styledinput input[type=email][disabled], .wh-styledinput input[type=email][readonly],
.wh-styledinput input[type=password][disabled], .wh-styledinput input[type=password][readonly],
.wh-styledinput input[type=number][disabled], .wh-styledinput input[type=number][readonly],
.wh-styledinput input[type=date][disabled], .wh-styledinput input[type=date][readonly],
.wh-styledinput input[type=search][disabled], .wh-styledinput input[type=search][readonly],
.wh-styledinput textarea[disabled], .wh-styledinput textarea[readonly],
.wh-styledinput select[disabled],
.wh-styledinput select[disabled] + .custom-pulldown:hover,
.wh-styledinput select[disabled] + .custom-pulldown:focus {
  background-color: #f0f1f2;
  color: rgba(0, 0, 0, 0.4);
}