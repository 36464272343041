/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
* {
  box-sizing: border-box;
}

html,
body,
form {
  padding: 0;
  margin: 0;
}

html {
  background: transparent url("../web/img/campus.jpg") no-repeat fixed center center;
  background-size: cover;
}

body {
  height: 100%;
  width: 100%;
  background: transparent url("../web/img/raster.png") repeat fixed top left;
}

.ru--show-if-nl:not(:lang(nl)),
.ru--show-if-en:not(:lang(en)),
.ru--show-if-de:not(:lang(de)) {
  display: none;
}

.rufairs__message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #fff;
  margin: 0 auto;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.appviewport {
  position: relative;
  align-self: center;
  width: min(100%, 1024px);
  height: 768px;
  background-color: #FFFFFF;
  background-clip: content-box;
  box-sizing: content-box;
}

.appviewport-abs {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

html {
  font: normal 13px "TypoPRO Open Sans";
}

@media (max-width: 960px) {
  html, body {
    height: 100%;
  }
  .appviewport {
    width: 100%;
    height: 100%; /* 100%, because 100vh also counts the area used by the scrollbar */
  }
}
@media (min-width: 961px) {
  html {
    color: #222222;
    height: 100vh;
    overflow: hidden;
  }
  body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: transparent url("../web/img/raster.png") repeat fixed top left;
  }
  .appviewport {
    border: 25px solid rgba(255, 255, 255, 0.69);
  }
}
/* if we are very tight on vertical space (on a cheap laptop or small tablet)
   remove the top and border whitespace
*/
@media (max-height: 767px) {
  .appviewport {
    border-top: 0;
    border-bottom: 0;
  }
}
@media (min-height: 768px) {
  /* vertically align, but only if it will fit */
  body {
    justify-content: center;
  }
}
@media (max-height: 767px) {
  html {
    overflow-y: scroll;
  }
}
.page,
.dialog {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.page:not(.page--active) {
  display: none;
}

.page--active,
.dialog--active {
  z-index: 0;
}

.page__headerbg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 72px;
}

.page__footerbg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20px;
}

.page__column__content {
  /* positioned so all content will be shown over the header/footer */
  position: relative;
}

.page__scrollcontainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.column__header {
  position: relative; /* to be drawn over the abs.positioned bg */
  font: bold 16px/40px "TypoPRO Open Sans";
  text-transform: uppercase;
  color: #FFFFFF;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 30px;
  justify-content: space-between;
  display: flex;
  flex-shrink: 0;
}

.wh-form__prefillarea.wh-form__prefillarea {
  position: absolute;
  top: 40px;
  left: 20px;
  height: auto;
  z-index: 1;
}

.wh-form__prefillarea.wh-form__prefillarea > select {
  position: relative;
  width: auto;
}

#dialog-login__loginbutton {
  width: 100%;
  margin-top: 10px;
}

.page--registration {
  display: flex;
}

.page__column {
  position: relative;
}

.page--registration > .page__column {
  width: 33.333%;
  max-width: 33.333%;
  overflow: hidden;
  height: calc(100% - 20px);
}

.langselector {
  font-weight: normal;
  padding-bottom: 3px;
  border: 1px solid transparent;
  font-size: 15px;
}

.langselector__lang {
  opacity: 0.54;
  cursor: pointer;
}

.langselector__lang.disabled {
  display: none;
}

.langselector__lang + .langselector__lang {
  margin-left: 15px;
}

.langselector__lang.selected {
  opacity: 1;
  border-bottom: 1px solid #FFFFFF;
}

.tooltip {
  min-width: 100px;
  max-width: 320px;
}

.popper,
.tooltip {
  position: absolute;
  color: #FFFFFF;
  background-color: #BE311A;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  padding: 10px;
  border: 1px solid #F88;
}

.style5 .tooltip {
  background: #1E252B;
  color: #FFFFFF;
  max-width: 200px;
  width: auto;
  font-size: 0.8rem;
  padding: 0.5em 1em;
}

.popper .popper__arrow,
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}

.tooltip .tooltip-arrow,
.popper .popper__arrow {
  border-color: #BE311A;
}

.style5 .tooltip .tooltip-arrow {
  border-color: #FFFFFF;
}

.popper[x-placement^=top],
.tooltip[x-placement^=top] {
  margin-bottom: 5px;
}

.popper[x-placement^=top] .popper__arrow,
.tooltip[x-placement^=top] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.popper[x-placement^=bottom],
.tooltip[x-placement^=bottom] {
  margin-top: 5px;
}

.tooltip[x-placement^=bottom] .tooltip-arrow,
.popper[x-placement^=bottom] .popper__arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^=right],
.popper[x-placement^=right] {
  margin-left: 5px;
}

.popper[x-placement^=right] .popper__arrow,
.tooltip[x-placement^=right] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.popper[x-placement^=left],
.tooltip[x-placement^=left] {
  margin-right: 5px;
}

.popper[x-placement^=left] .popper__arrow,
.tooltip[x-placement^=left] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}