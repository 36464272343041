@charset "UTF-8";
/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder {
  opacity: 0.4;
}

::-moz-placeholder {
  opacity: 0.4;
}

input:-moz-placeholder {
  opacity: 0.4;
}

.wh-styledinput input[type=checkbox] + label:before,
.wh-styledinput input[type=radio] + label:before .wh-styledinput input[type=checkbox] + .label > label:first-child:before,
.wh-styledinput input[type=radio] + .label > label:first-child:before {
  vertical-align: middle;
}

.wh-styledinput input[type=radio] + label:before,
.wh-styledinput input[type=checkbox] + label:before,
.wh-styledinput input[type=radio] + .label > label:first-child:before,
.wh-styledinput input[type=checkbox] + .label > label:first-child:before {
  content: " "; /* non breaking space */
  display: inline-block;
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  /* ::before doesn't get targetted by the * selector */
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  font: normal normal normal 9px/18px FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  border: 1px solid #D4D4D4;
  border: 1px solid var(--color-grey-border-radboud);
  background-color: #ffffff;
}

.wh-styledinput input[type=checkbox] + label:before,
.wh-styledinput input[type=checkbox] + .label > label:first-child:before {
  font-size: 14px;
}

.wh-styledinput input[type=checkbox],
.wh-styledinput input[type=radio] {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}

.wh-styledinput input[type=radio] + label:hover:before,
.wh-styledinput input[type=checkbox] + label:hover:before,
.wh-styledinput input[type=radio]:focus + label:before,
.wh-styledinput input[type=checkbox]:focus + label:before,
.wh-styledinput input[type=radio] + .label > label:first-child:hover:before,
.wh-styledinput input[type=checkbox] + .label > label:first-child:hover:before,
.wh-styledinput input[type=radio]:focus + .label > label:first-child:before,
.wh-styledinput input[type=checkbox]:focus + .label > label:first-child:before {
  border: 1px solid #5A5A5A;
  background-color: #ffffff;
  color: #E0E0E0;
}

.wh-styledinput input[type=radio]:checked + label:before,
.wh-styledinput input[type=checkbox]:checked + label:before,
.wh-styledinput input[type=radio]:checked + .label > label:first-child:before,
.wh-styledinput input[type=checkbox]:checked + .label > label:first-child:before {
  color: #FFFFFF;
  background-color: #3DABDD;
  border: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.26);
}

.wh-styledinput input[type=radio][disabled] + label:before,
.wh-styledinput input[type=checkbox][disabled] + label:before,
.wh-styledinput input[type=radio][disabled] + .label > label:first-child:before,
.wh-styledinput input[type=checkbox][disabled] + .label > label:first-child:before {
  opacity: 0.3;
  cursor: default;
}

.wh-styledinput input[type=radio] + label:before,
.wh-styledinput input[type=radio] + .label > label:first-child:before {
  border-radius: 100%;
}

.wh-styledinput input[type=radio]:checked + label:before,
.wh-styledinput input[type=radio]:checked + .label > label:first-child:before {
  content: "\f111"; /* check */
}

.wh-styledinput input[type=checkbox] + label:before,
.wh-styledinput input[type=checkbox] + .label > label:first-child:before {
  border-radius: 3px;
}

.wh-styledinput input[type=checkbox]:checked + label:before,
.wh-styledinput input[type=checkbox]:checked + .label > label:first-child:before {
  content: "\f00c"; /* check */
}

.label > label:first-child:before {
  margin-right: 10px;
}