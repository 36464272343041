.column__header {
  padding: 0 20px 0 20px;
}

.column__content {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}

.column__content--padded {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
}

.column__submitbuttons {
  background: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  padding: 10px 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.column__submitbuttons--next {
  justify-content: flex-end;
}

@media (max-width: 960px) {
  .page--registration__resetbutton, .column--widemodeonly, .page__footerbg {
    display: none;
  }
  .page__column:not(.page__column--current) {
    display: none;
  }
  .page--registration > .page__column {
    width: 100%;
    max-width: none;
    height: 100%;
  }
  .column__content {
    overflow: scroll;
  }
  .registration__personal, .registration__interests {
    border-right: none;
  }
}
@media (min-width: 961px) {
  .column--smallmodeonly, .column__submitbuttons, .column__header__steps, .column__langselector__select {
    display: none;
  }
}
.column__langselector__select {
  font-weight: normal;
  border: none;
  color: #ffffff;
  -webkit-appearance: none;
  background: url(selectarrow.svg) no-repeat 101% 30%;
}