#pwadebugmenu {
  all: initial;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
}
#pwadebugmenu * {
  all: initial;
}
#pwadebugmenu div {
  display: block;
}
#pwadebugmenu button {
  background: white;
  padding: 5px;
}