/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
.custom-pulldown {
  display: none;
}

.custom-pulldown--hiddenitem {
  display: none;
}

.custom-pulldown {
  display: inline-block;
  height: var(--formcontrols-height);
  cursor: default;
  vertical-align: middle;
  color: var(--color-black);
  border: 1px solid var(--formcontrols-border-color);
  padding-left: var(--textcontrols-padleft);
  padding-right: 27px;
  /* The SVG image needs preserveAspectRatio="xMaxYMid" to get positioned at the right in IE10/11 (and might also need the viewBox) */
  background: #FFFFFF var(--formcontrol-pulldown-icon) no-repeat calc(100% - 7px) 50%;
  /*
    html[dir=ltr] &__arrow
    {
      right:0;
    }
    html[dir=rtl] &__arrow
    {
      left:0;
    }
  */
}
.custom-pulldown--replaced {
  display: none;
}
.custom-pulldown__area {
  height: 100%; /* This area */
  overflow: hidden;
}
.custom-pulldown__control {
  height: 100%;
  position: relative;
}
.custom-pulldown__items {
  /*min-height:100%;*/
}
.custom-pulldown__items--open {
  position: fixed;
  cursor: default;
  overflow: auto;
}
.custom-pulldown__item--disabled:first-child {
  display: none;
}
.custom-pulldown__current {
  border-radius: 2px;
  background: var(--color-white);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-pulldown__current--disabled {
  border-color: var(--color-grey-lines);
  color: var(--color-grey-lines);
}
.custom-pulldown__control {
  display: flex;
  align-items: center;
}
.custom-pulldown__control:hover .custom-pulldown__current {
  border-color: var(--color-grey-normal);
}
.custom-pulldown__control:hover .custom-pulldown__current--disabled {
  color: var(--color-grey-dark);
}
.custom-pulldown:focus .custom-pulldown__current {
  border-color: 1px solid var(--color-grey-dark);
}
html[dir=ltr] .custom-pulldown__current {
  padding: 5px 25px 5px 10px;
}
html[dir=rtl] .custom-pulldown__current {
  padding: 5px 10px 5px 25px;
}
.custom-pulldown--disabled .custom-pulldown__current {
  background-color: var(--color-grey-light);
  color: rgba(0, 0, 0, 0.4);
}
.custom-pulldown__items {
  min-width: 165px;
  background: var(--formcontrols-dropdown-background);
  border: var(--formcontrols-dropdown-border);
  border-top: 0;
  margin-top: -1px;
}
.custom-pulldown__items--open {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  z-index: 999999;
}
.custom-pulldown__item {
  font: normal 12px/20px Verdana, Arial;
  color: #222;
  padding: 5px 10px;
  cursor: pointer;
}
.custom-pulldown__optgroup {
  font: italic 12px/20px Verdana, Arial;
  color: var(--color-grey-dark);
  padding: 5px 10px;
}
.custom-pulldown--ingroup {
  padding-left: 20px;
}
.custom-pulldown__item--disabled {
  opacity: 0.5;
}
.custom-pulldown__arrow {
  display: none;
}
.custom-pulldown__item--selected {
  background-color: var(--formcontrols-option-hover-background) !important;
  color: var(--formcontrols-option-hover-textcolor) !important;
}
.custom-pulldown__item:hover {
  background-color: var(--formcontrols-option-hover-background) !important;
  color: var(--formcontrols-option-hover-textcolor) !important;
}
.wh-form__field--error + .custom-pulldown .custom-pulldown__current {
  border-color: #cd202c;
  color: #cd202c;
  background-color: rgba(205, 32, 44, 0.05);
}
.wh-form__field--error + .custom-pulldown .custom-pulldown__arrow::after {
  color: #cd202c;
}

.custom-pulldown .custom-pulldown__items {
  display: none;
}

.custom-pulldown__items {
  z-index: 50;
  background-color: #FFFFFF;
}