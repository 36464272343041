/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
.registration__personal {
  /* make flex so we inherit our height (the full column height)
     making us able to use margin-top: auto; to push the reset button to the bottom of the column.
  */
  display: flex;
  border-right: 1px solid #D4D4D4;
  flex: 1;
}

.registration__personal .page__column__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

[name=dateofbirth_day],
[name=dateofbirth_month],
[name=dateofbirth_year] {
  width: 0;
}

[name=dateofbirth_month],
[name=dateofbirth_year] {
  margin-left: 15px;
}

.page--registration__resetbutton {
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}