/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
/*

.wh-form
  - Any form adhiring to the standard as set for Publisher forms
    (which includes Webpacks integrated using the Publisher formsintegration plugi9n)

.wh-styledinput
  - Opt-in to ws2016 custom styled components.
    This requires any <input type="checkbox" /> or <input type="radio" /> to be followed by a <label for="id_of_input"></label> as placeholder.



Stretch a field within a .wh-form

    #myformid input[name="email"] { flex: 1; }

Disable stretching from .wh-form--justify in a single field:

    #myformid input[name="email"] { flex: 0; }

Stretch all textual fields within a .wh-form

    opt-in to .wh-form--justify

*/
label[for] {
  cursor: pointer;
}

.wh-form__fieldgroup--hidden {
  display: none;
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

/*
@media (max-width: ($form_minwidth_sidebyside - 1px))
{
*/
.wh-form__fieldgroup.fieldgroup--vertical {
  flex-direction: column;
}

.wh-form__fieldgroup.fieldgroup--vertical .wh-form__fields {
  width: 100%;
}

/*
  .wh-form__fieldline
  {
    display: flex;
    align-items: baseline;
  }
*/
.wh-form__fieldgroup + .wh-form__fieldgroup {
  margin-top: 15px;
}

.wh-form__fieldgroup.fieldgroup--horizontal .wh-form__label {
  width: 100px;
}

.wh-form__fieldgroup.fieldgroup--horizontal .fields--radio .wh-form__fieldline {
  width: auto;
}

.wh-form__fieldgroup.fieldgroup--horizontal .fields--radio .wh-form__fieldline + .wh-form__fieldline {
  margin-left: 15px;
}

.wh-form__fieldgroup.fieldgroup--horizontal .fields--radio .wh-form__fieldline > label::before {
  margin-right: 10px;
}

.wh-form__fieldgroup.fieldgroup--vertical .wh-form__label {
  margin-bottom: 5px;
  margin-right: 0;
  width: 100%; /* needed for IE10/11 (not for Edge or modern browsers) */
}

.wh-form__fieldline {
  align-items: center !important;
}

/*
}
*/
.wh-form__fieldgroup + button {
  margin-top: 19px;
}