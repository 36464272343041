/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
.registration__summary {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: inset 8px 8px 25px rgba(0, 0, 0, 0.1);
}

.registration__summary .page__column__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.langselector {
  margin-left: auto;
}

.registration__eventname {
  font: bold 18px "Noto Serif";
  color: #BE311A;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #D4D4D4;
}

.ru_logo {
  max-width: 100%;
  margin-top: 20px;
}

.ru_logo--nl:not(:lang(nl)) {
  display: none;
}

.ru_logo--int:lang(nl) {
  display: none;
}

.registration__box,
.registration__submitbutton {
  margin-top: 10px;
}

.registration__box {
  background-color: #F5F5F5;
  border: 1px solid #D4D4D4;
  padding: 20px;
  border-radius: 3px;
}

.registration__box input + label::before {
  margin-right: 10px;
}

.registration__box textarea {
  width: 100%;
  height: 70px;
  resize: none;
  border: 1px solid #5A5A5A;
  padding: 15px;
  margin-top: 15px;
}

.registration__selection {
  flex: 1;
}

.selection__item {
  display: flex;
  align-items: center;
}

.selection__item::before {
  display: inline-block;
  margin-right: 15px;
  color: #BE311A;
  /*font: normal normal normal 16px FontAwesome;*/
  font: normal normal normal 15px FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  content: "\f00c"; /* check */
}

.selection__item__title {
  flex-grow: 1;
  /* we allow multiline. weird extremely long words will get ellipsis */
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 5px;
  padding-bottom: 5px;
}

.selection__item__closebutton {
  margin-left: 10px;
  /*
    font: normal normal normal 16px FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);

    content: "\f054";
  */
  flex-shrink: 0;
  background: transparent url("../web/img/close-grey.svg") no-repeat center center;
  background-size: 11px 11px;
  width: 11px;
  height: 11px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.registration__question textarea {
  display: none;
}

.registration__question.question--active textarea {
  display: block;
}

.explainfuturecommunication {
  text-decoration: underline;
  color: #0066FF;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}

.controlfieldline > label,
.controlfieldline > .label {
  display: flex;
}

.controlfieldline > label > input,
.controlfieldline > .label > input {
  flex: none;
}

.controlfieldline > label > .label__text,
.controlfieldline > .label > .label__text {
  flex: auto;
}

.logitem {
  display: flex;
}

.logitem::before {
  content: "-";
  margin-right: 10px;
}

.logitem__when {
  flex-shrink: 0;
  margin-right: 20px;
}