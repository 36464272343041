/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
.dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #BE311A;
  color: #FFFFFF;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s 0s;
}

.dialog--active {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.5s 0s;
}

.dialog__chrome {
  align-self: center;
  width: 300px;
}

.dialog__closebutton {
  display: block;
  margin-left: auto;
  background: transparent url("../web/img/close-white.svg") no-repeat scroll center center;
  background-size: 15px 15px;
  width: 15px;
  height: 15px;
  /* enlarge clickable area */
  position: relative;
  right: -10px;
  padding: 10px;
  box-sizing: content-box;
  cursor: pointer;
}

.dialog__content {
  position: relative;
  min-height: 135px;
  padding: 20px;
  background-color: #FFFFFF;
}

.dialog__title {
  color: #BE311A;
  font: bold 16px "TypoPRO Open Sans";
  text-transform: uppercase;
}

.dialog__title + .dialog__body {
  margin-top: 15px;
}

.dialog__body {
  color: #222222;
}

.dialog__logo {
  margin: 30px auto 0 auto;
  max-width: 100%;
}

.dialog__connectwithus {
  margin: 15px 0 10px;
}

.dialog__connectsocials {
  margin: 10px 0;
}

a.dialog__connectsocial {
  display: inline-block;
  border: 1px solid #ffffff;
  font-size: 15px;
  border-radius: 100%;
  text-align: center;
  width: 30px;
  height: 30px;
  padding-top: 7px;
  color: #ffffff;
  text-decoration: none;
}

.dialog__connectsocial + .dialog__connectsocial {
  margin-left: 10px;
}

.dialog__learnmore {
  margin-top: 20px;
}

.dialog__learnmorelink {
  color: #ffffff;
}