/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
.registration__interests {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid #D4D4D4;
}

.registration__interests .page__column__content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.registration__interests .column__header {
  margin-bottom: 16px;
}

.registration__interests__tabstrip {
  margin-top: 10px;
}

.tabstrip {
  display: flex;
  flex-shrink: 0;
  height: 35px; /* prevent collapsing, especially in IE */
  border-bottom: 2px solid #D4D4D4;
  -webkit-tap-highlight-color: transparent;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.tabstrip__tab {
  flex-grow: 1;
  flex-shrink: 0; /* MUST fit the content */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 35px;
  box-sizing: content-box; /* border-bottom will add to it's height */
  color: #A7A7A7;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.tabstrip__tab.disabled {
  display: none;
}

.tabstrip__tab:active {
  color: #BE311A;
}

.tabstrip__tab.selected {
  color: #BE311A;
  border-bottom: 2px solid #BE311A;
}

.tabpanel {
  display: flex;
  flex-direction: column;
  /* test fix for Safari 9 */
  flex-shrink: 1;
  /* moved from .programmeslist to here, seems to fix an IE issue */
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* FIXME: I'd rather not do this, but this makes it work in Edge.
            This makes the page less fluid though.
  */
  max-height: 647px;
}

.tabpanel:not(.selected) {
  display: none;
}

.programmeslist {
  /*
  moved to .tabpanel, because that way it also works on IE
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  */
}

.checkboxlist__branchtoggle {
  order: 1;
}

.checkboxlist__item__title {
  order: 2;
}

.checkboxlist__item > input + label {
  order: 3;
}

.checkboxlist__item {
  display: flex;
  align-items: center;
  padding: 0 25px;
  border-bottom: 2px solid #F5F5F5;
  /*
    background-color: rgba(200,255,255,0);
    transition: background-color 0.25s;
  */
}

/*
.checkboxlist__item:active
{
  background-color: rgba(230,230,255,1);
}
*/
.checkboxlist__branchtoggle,
.checkboxlist__item__title,
.checkboxlist__item > input + label {
  /* use padding within items in row to have a large hitarea */
  padding-top: 9px;
  padding-bottom: 9px;
  -webkit-tap-highlight-color: transparent;
}

.checkboxlist__branchtoggle {
  /* use padding to increase hitarea size */
  padding-right: 15px;
  margin-left: -15px;
  padding-left: 15px;
}

.checkboxlist__item__title {
  margin-right: 15px;
}

/* checkbox / title / toggle */
/*
.checkboxlist__item > input + label::before
{
  margin-right: $margin_small;
}
.checkboxlist__item.canexpand .checkboxlist__branchtoggle
{
  margin-left: $margin_small;
}
*/
.checkboxlist__item__title {
  flex-grow: 1;
  /* we allow multiline. weird extremely long words will get ellipsis */
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.checkboxlist__branchtoggle {
  visibility: hidden;
}

.checkboxlist__item.canexpand .checkboxlist__branchtoggle {
  visibility: visible;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.checkboxlist__branchtoggle::before {
  display: inline-block; /* transforms only work on block elements */
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  /*
    font: normal normal normal 16px FontAwesome;
    content: "\f061";  arrow right 
  */
  font: normal normal normal 24px FontAwesome;
  /*  content: "\f107"; angle up */
  content: "\f105";
  flex-shrink: 0;
  transition: transform 0.25s;
}

.checkboxlist__item.expanded .checkboxlist__branchtoggle::before {
  transform: rotate(90deg);
  /*  content: "\f106";  angle down */
}

.checkboxlist__item.canexpand .checkboxlist__branchtoggle:hover {
  opacity: 1;
}

.checkboxlist__subitems {
  color: #5A5A5A;
  font-style: italic;
  overflow: hidden;
  height: 0;
  background-color: #FAFAFA;
  transition: height 0.25s ease-in;
}

.checkboxlist__subitems .checkboxlist__item {
  margin-left: 25px;
  padding-left: 0;
}