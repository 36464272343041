/* bg for personal column and admin settings column */
/* lightgrey for dividers, add. questions border */
/* grey */
/* red */
/* red */
/* between question + submit */
/* between language options */
/* dialog + logo */
/* extra large padding to not have content
   disappear under footer image
*/
/* min required to not get under the footer image */
/*
$fieldgroup_margin:      16px;
*/
/* FIXME input-to-label -> 9px.... input-to-button-> 16px; */
html {
  --color-grey-border: #929292; /* WCAG 3:1 contrastratio to white for UI elements (NOT text) */
  --color-grey-border-radboud: #CCCCCC; /* not WCAG AA, but better than the old design */
  /*
    // OLD - FA5-Solid angle down
    --background-icon-angle-down-solid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' width='18' %3E%3Cpath fill='%2301689b' d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'/%3E%3C/svg%3E");
    // no-repeat calc(100% - 17px) calc(50% + 2px);

    // OLD - FA-Regular angle down (used in minfin_wig)
    --background-icon-angle-down-regular: url("data:image/svg+xml,%3Csvg height='192' viewBox='0 0 322 192' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2301689b' d='m152.5 187.8-148-146.8c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0l119.7 118.5 119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'/%3E%3C/svg%3E");
    // no-repeat calc(100% - 17px) calc(50% + 2px);
  */
  --background-icon-updown: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNYXhZTWlkIiB2aWV3Qm94PSIwIDAgNC45NSAxMCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOiM0NDQ7fTwvc3R5bGU+PC9kZWZzPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQuOTUiIGhlaWdodD0iMTAiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMS40MSA0LjY3IDIuNDggMy4xOCAzLjU0IDQuNjcgMS40MSA0LjY3Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIDMuNTQgNS4zMyIvPjwvc3ZnPg==);
  --formcontrol-pulldown-icon: var(--background-icon-updown);
  --formcontrol-pulldown-background-size: auto;
  /************************************************************
   *
   *  Form controls (textual fields)
   */
  /* Normal height for single-line controls */
  --formcontrols-height: 35px;
  --formcontrols-border-color: var(--color-grey-border-radboud);
  --formcontrols-radius: 3px;
  /*

    --formcontrols-focus-boxshadow:       0 0 0 3px #540A5130;
    --formcontrols-focus-outline:         2px solid #0000AA;
    --formcontrols-focus-outline-offset:  3px;

    --formcontrols-border-width:          1px;
    --formcontrols-border-width-focus:    2px;

    --formcontrols-border-color:          #767676;
    --formcontrols-border-color-disabled: #BFBFBF;
    --formcontrols-border-color-error:    var(--color-salmon-formcontrols-error-border);
    --formcontrols-border-color-focus:    var(--color-purple-dark);

    --formcontrols-textcolor-error:       #121212; / * we make the area around it red and keep the text black * /

    --formcontrols-backgroundcolor-error: #FFFFFF;



    --formcontrol-checkradio-label-color: var(--rtd-text-color);
  */
  /* NOTE: if the font-size is below 16px iOS will zoom into the control when it recieves focus */
  /*
    --textcontrols-font:                 19px var(--rtd-text-fontfamily);
    --textcontrols_placeholdercolor:     #767676;
    --textcontrols-bgcolor:              #FFFFFF;
    --textcontrols_textcolor:            #333333;

    --textcontrols_disabled_bgcolor:     #F5F5F5;
   / *--textcontrols_disabled_bordercolor: #989898;* /
    --textcontrols_disabled_placeholder: #767676;
    --textcontrols_disabled_textcolor:   #767676;

    --textcontrols_padleft-mobile:  10px;
    --textcontrols_padright-mobile: 10px;


    --controls-icon-color: var(--color-theme);
  */
  --textcontrols-padleft: 10px;
  --textcontrols-padright: 10px;
  --formcontrols-option-hover-background: #F0F0F0;
  --formcontrols-option-hover-textcolor: #000000;
  --formcontrols-dropdown-background: #FFFFFF;
  --formcontrols-dropdown-border: 1px solid #BBBBBB;
}

.registration__box {
  --color-grey-border-radboud: #B3B3B3;
}